import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
//import { MatButtonModule, MatIconModule,MatDialogModule } from '@angular/material';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FlexLayoutModule } from "@angular/flex-layout";
import { fuseConfig } from 'app/fuse-config';
//import { ToastrModule } from 'ngx-toastr';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthGuard } from "./auth.guard";
import { LoginserviceService } from "./main/pages/authentication/login/loginservice.service";
import { LoginModule } from "app/main/pages/authentication/login/login.module";
import { LoginComponent } from "./main/pages/authentication/login/login.component";
import { ConfirmationDialogComponent } from 'app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { DialogComponent } from 'app/components/shared/dialog/dialog.component';
import { RefreshComponent } from "./refresh.component";
import { HashLocationStrategy, LocationStrategy, DecimalPipe } from '@angular/common';
import { SharedModule } from "app/shared/shared.module";
import { ToastrModule } from 'ngx-toastr';
import { LoaderService } from 'app/loader.service';
import { LoaderInterceptor } from "./loader.interceptor";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material';
import {
  MomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { AppRoutingModule } from './app-routing.module'
import { MatDialogModule, MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ProfileComponent } from './profile/profile.component';
import { SalesOrderModule } from './sales-order/sales-order.module';

import {MatTableModule} from '@angular/material/table';
//import { AutoCompleteDirective } from './auto-complete.directive';

//create our cost var with the information about the format that we want
export const MY_FORMATS = {
  parse: {
    dateInput: 'yyyy-mm-dd',
  },
  display: {
    dateInput: 'yyyy-mm-dd',
    //  monthYearLabel: 'MM YYYY',
    // dateA11yLabel: 'DD/MM/YYYY',
    // monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    FuseConfirmDialogComponent,
    ConfirmationDialogComponent,
    DialogComponent,
    RefreshComponent,
    ProfileComponent,
    
    // AutoCompleteDirective    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    //  RouterModule.forRoot(appRoutes),
    FlexLayoutModule,
    ToastrModule.forRoot(
      {
         closeButton: true,
         positionClass: 'toast-center-center',
        // timeOut: 3000,
      }
    ),
    TranslateModule.forRoot(),
    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    LoginModule,
    SharedModule,
    AppRoutingModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    SalesOrderModule,
    FuseSharedModule,
    MatTableModule
    
    //ToastrModule.forRoot()       

  ],

  
  exports: [

  ],
  providers: [
    DecimalPipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    //   { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    /* {
    provide: MAT_DATE_FORMATS,
    useValue: MY_FORMATS
},
//you can change
/*useValue {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE]
}, {
    provide: MAT_DATE_FORMATS,
    useValue: MY_FORMATS
}*/
  ],

  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    DialogComponent,
    FuseConfirmDialogComponent,
    
  ],
})
export class AppModule {
}
