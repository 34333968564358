import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule,MatGridListModule } from '@angular/material';
import { SalesOrderComponent } from './sales-order.component';

const routes: Routes = [
  
  //dasboard
  {
    path: '**',
    redirectTo: 'apps/dashboards/analytics'
  },
  {
    path: "list",
    component: SalesOrderComponent
  },

];
@NgModule({
  declarations: [SalesOrderComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatGridListModule
  ],
  exports     : [
    SalesOrderComponent
]
})
export class SalesOrderModule { }
