import { Component, OnInit, Inject } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-refresh",
  template: "<h1>Reresh</h1>"
})
export class RefreshComponent implements OnInit {
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog //@Inject(MAT_DIALOG_DATA) private _data: any
  ) {}

  ngOnInit() {}
}
