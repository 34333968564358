import { Component, OnInit , ViewChild, ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { FileExplorerService } from 'app/shared/services/file-explorer.service';
import { fuseAnimations } from '@fuse/animations';
export class projects {
  id: string;
  reference: string;
}
@Component({
  selector: 'app-sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['./sales-order.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations : fuseAnimations
}) 
export class SalesOrderComponent implements OnInit {
  customer_id: string;
  projects: projects[];
  constructor(
    private router: Router,
    private fileservice:FileExplorerService,
  ) { }

  ngOnInit() {
    this.customer_id=localStorage.getItem("user_id");
    this.fileservice.getprojectlist(this.customer_id).subscribe(result => {
      console.log(result);
      this.projects=result.response;
    }, err => {
      console.log(err);
    });

  }

  viewfolders(project_id:any){
    let customer_id=this.customer_id;
    this.router.navigate(["/fileexplorer/files",customer_id,project_id]);
  }

}
