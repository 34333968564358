import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from 'app/shared/services/jwt.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     // we are sending a file here
     const token = this.jwtService.getToken();
     const user_id = this.jwtService.getLoginUser();

    if (req.body instanceof FormData) {
      const headersConfig = {

            };    

      if (token) {
        headersConfig['Authorization'] = `${token}`;
      }
      if(user_id){
        headersConfig['Loginuser'] = `${user_id}`;
      }

      const request = req.clone({ setHeaders: headersConfig });
      return next.handle(request);
    } else {
      const headersConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };
      const token = this.jwtService.getToken();

      if (token) {
        headersConfig['Authorization'] = `${token}`;
      }
      if(user_id){
        headersConfig['Loginuser'] = `${user_id}`;
      }

      const request = req.clone({ setHeaders: headersConfig });
      return next.handle(request);
    }

  }
}