import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor() { }

  getLoginUser(): any {
    return window.localStorage['user_id'];
  }

  getToken(): any {
    return window.localStorage['token'];
  }

  saveToken(token: any) {
    console.log("token",token)
    window.localStorage['token'] = token;
  }

  destroyToken() {
    window.localStorage.removeItem('token');
  }
}
