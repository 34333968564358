import { Component, OnInit, ViewEncapsulation,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterModule, Router } from "@angular/router";
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { fuseAnimations } from '@fuse/animations';
import { navigation } from "app/navigation/navigation";
import { LoginserviceService } from "./loginservice.service";
import { JwtService } from 'app/shared/services/jwt.service';
import { ToastrService } from 'ngx-toastr';
export class LoginUserData {
  username: any;
  password: any;
}
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
  loginUserData: any;
  loginForm: FormGroup;
  msg = "";
  IsGetOtp:boolean; 
  ISSendOTP:boolean=true;
  //navigation: import("/var/www/html/Road_Side/Actplra_Local_Code/src/@fuse/types/fuse-navigation").FuseNavigation[];
  otp: string;
  showOtpComponent = true;
  @ViewChild('ngOtpInput') ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '70px',
      'height': '60px'
    }
  };
  verifiedotp: any;
  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _router: Router,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _formBuilder: FormBuilder,
    private _login: LoginserviceService,
    private toastr: ToastrService,
    private jwtservice:JwtService
  ) {
      // redirect to home if already logged in
      const loginuser=this.jwtservice.getLoginUser();
      if (loginuser) {
        this._router.navigate(['/apps/dashboards/project']);
      }
    this.loginUserData = new LoginUserData();
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  sendOTP(){
    this._login.sendOTP(this.loginUserData).subscribe(
      res => {
        if (res.responseCode == "200") {
         this.IsGetOtp=true;
         this.ISSendOTP=false;
         this.otp = res.response;
         //this.setValueotp(res.response);
        }
        else {
         
          this.toastr.error(res.message);
        }
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }

  onOtpChange(otp) {
    this.verifiedotp = otp;
  }

  setValueotp(val) {
  //  this.ngOtpInput.setValue(val);
 // this.ngOtpInput.setVal(val);
  }
  verify() {
    this._login.loginUser(this.loginUserData).subscribe(
      res => {
        if (res.responseCode == "200") {
          localStorage.setItem("user_id", res.user_id);
          localStorage.setItem("name", res.name);
          localStorage.setItem("email", res.email);
          localStorage.setItem("token", res.token);
         
          this._router.navigate(['/apps/dashboards/project']);
        }
        else {
         
          this.toastr.error(res.message);
        }
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
 	username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  check(uname: string, p: string) {
    var output = this._login.checkusernameandpassword(uname, p);
    if (output == true) {

      this._router.navigate(['/apps/dashboards/project']);
    }
    else {
      this.msg = 'Invalid username or password';
    }


  }

}
