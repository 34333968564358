import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {OnlyNumberDirective} from 'app/Validators/Custom-Validator/only-number.directive'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { JwtService } from './services/jwt.service';
import { LoaderComponent } from "app/loader/loader.component";
import {NoRightClickDirective} from './directives/no-right-click.directive';

@NgModule({
  declarations: [LoaderComponent, OnlyNumberDirective,NoRightClickDirective],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
   ],
  exports: [
	OnlyNumberDirective,
  LoaderComponent,
  MatProgressSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    JwtService
  ]
})
export class SharedModule {}
