import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboards',
                title: 'Dashboard',
                translate: 'NAV.DASHBOARDS',
                type: 'item',
                icon: 'dashboard',
                url: 'apps/dashboards/project'
            },
           /* {
                id: 'salesorder',
                title: 'salesorder',
                translate: 'Sales Order',
                type: 'item',
                icon: 'snippet_folder',
                url: 'salesorder/list'
            },*/
        ]
    },
];
