import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { LoginserviceService } from "./main/pages/authentication/login/loginservice.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(private _login: LoginserviceService, private _router: Router) {}

  canActivate(): boolean {
    if (this._login.loggedIn()) {
      return true;
    } else {
      this._router.navigate([""]);
      location.reload();
      return false;
    }
  }
}
