import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpClientModule,
  HttpHeaders
} from "@angular/common/http";
import { from, Observable } from "rxjs";
import { environment } from "environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With",
    "Access-Control-Request-Headers":
      "access-control-allow-origin, content-type"
  })
};

@Injectable({
  providedIn: "root"
})
export class LoginserviceService {
   Url: string;
  token: string;
  header: any;
  constructor(private http: HttpClient) {
        this.Url = "http://actplradevapi.disctesting.in/index.php";
 const headerSettings: { [name: string]: string | string[] } = {};
    this.header = new HttpHeaders(headerSettings);
 }
  checkusernameandpassword(uname: string, pwd : string)
  {
if(uname == "admin" && pwd =="admin123"){
localStorage.setItem('username',"admin");
return true;
}
else{
  return false;
}
  }

  loggedIn() {
    return !!localStorage.getItem("token");
  }

  loginUser(user: any) {
   console.log(user);
    return this.http.post<any>(  environment.url + "/ApiGM/Login", JSON.stringify(user));
  }

  sendOTP(user: any) {
    console.log(user);
     return this.http.post<any>(  environment.url + "/ApiGM/getOTP", JSON.stringify(user));
   }


  ForgotPassword(email: any): any { 
    return this.http.post( 
      environment.url + "/ApiGM/ForgotPassword", 
      JSON.stringify(email) 
    ); 
  } 
}
