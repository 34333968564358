import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {Http, ResponseContentType} from '@angular/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'app/shared/services/api.service';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FileExplorerService {

  handleError: (reason: any) => any; 
  constructor(
     private _httpClient: HttpClient,
     private apiService: ApiService
  ) { }


  getallprojectlist(customer_id): Observable<any> {
    return this.apiService.post('/ApiGM/getprojectlist',
      {
        customer_id:customer_id,
      }
      )
      .pipe(map(
        data => {
          return data;
        }
      ));
  }
  getprojectlist(customer_id): Observable<any> {
    return this.apiService.post('/ApiGM/getprojectlist',
      {
        customer_id:customer_id,
      }
      )
      .pipe(map(
        data => {
          return data;
        }
      ));
  }

  getdocumentlist(customer_id,project_id): Observable<any> {
    return this.apiService.post('/ApiGM/getdocumentlist',
      {
        customer_id:customer_id,
        project_id:project_id
      }
      )
      .pipe(map(
        data => {
          return data;
        }
      ));
  }

  getchilddocumentlist(customer_id,project_id,folder_id): Observable<any> {
    return this.apiService.post('/ApiGM/getdocumentlist',
      {
        customer_id:customer_id,
        project_id:project_id,
        folder_id:folder_id
      }
      )
      .pipe(map(
        data => {
          return data;
        }
      ));
  }



  getdoc(data,customer_id)
  {
 
    return this._httpClient.post(environment.url + "ApiGM/getSearchResult",{
      search_data:data,
      customer_id:customer_id
    }
    
    // return this._httpClient.post("ApiGM/getSearchResult&search_data=",data);
          // {
          //   observe: 'response',
          //   responseType: 'text'
          // }
          );
  }


 

  open_document() {
     return environment.url+ "customerSoFolder/opendocument";
  }

}
