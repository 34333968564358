import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import { merge, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseNavigation } from "@fuse/types";

import { navigation } from "app/navigation/navigation";
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { DoclistComponent } from "app/doclist/doclist.component";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";

// import {RefreshComponent} from "/src"

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService, public dialog: MatDialog,
        private router: Router,
        
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }


    // Search function

    
    data:any = {}; 
    result:any;
    textboxval:any;
    

    getval(item)
    {
        this.data.search_data = item.value;
        // alert(JSON.stringify(this.data)); 
        
        console.log(JSON.stringify(this.data));
        localStorage.setItem('search_data',this.data.search_data);
        // alert("bye");
        // alert(this.data.search_data);
        this.router.navigateByUrl('/app/refresh', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/doclist/searchdoc',this.data.search_data]);
});



    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        //  this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

             // Load the navigation
             this.navigation = this._fuseNavigationService.getCurrentNavigation();

             // Mark for check
             this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

            // this.textboxval=localStorage.getItem("search_data");
            // if(localStorage.getItem("search_data")!="")
            // {
            //     this.textboxval=localStorage.getItem("search_data");
            // }
            
    }
}
