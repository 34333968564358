import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject,HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  print_screen=44,
  inspectelement=123
}
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  imageurl:any;
  otherurl:any;
  audiourl:any;
  videourl:any;
  codecs:any;

  @HostListener('document:contextmenu', ['$event'])
  RightClick(event) {
    event.preventDefault();
    return false;

  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);
  console.log("KEY_CODE.RIGHT_ARROW"+KEY_CODE.RIGHT_ARROW);
    if (event.keyCode===KEY_CODE.RIGHT_ARROW) {
      event.stopPropagation();
      event.preventDefault();
      
    }
  
    if (event.keyCode===KEY_CODE.LEFT_ARROW) {
      alert("here");
      event.stopPropagation();
      event.preventDefault();
    }
     if (event.keyCode == KEY_CODE.print_screen || event.keyCode == KEY_CODE.inspectelement) {
      event.stopPropagation();
   //   console.log(event);
       event.preventDefault();
       }
  }
  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel) {
    // Update view with given values
    if(data.image){
      this.imageurl=this.sanitizer.bypassSecurityTrustResourceUrl(data.image);
    }
    if(data.otherdoc){
      this.otherurl=this.sanitizer.bypassSecurityTrustResourceUrl(data.otherdoc);
    }
    if(data.audio){
      this.audiourl=this.sanitizer.bypassSecurityTrustResourceUrl(data.audio);
    }
    if(data.video){
      this.videourl=this.sanitizer.bypassSecurityTrustResourceUrl(data.video);
      this.codecs=data.codecs;
    }
  }
 
  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  //public injectJS() {
    //let frame = document.getElementById("iframe");

   //var iFrameBody = frame.contentDocument.getElementsByTagName('body')[0].setAttribute("abcd","123");;
  /*  var frame = $('#iframe');
    var contents = frame.contents();
    var body = contents.find('body').attr("oncontextmenu", "return false");
    var body = contents.find('body').attr("oncopy", "return false");
    var body = contents.find('body').append('<div style="height:50000px;"></div>');*/
  //  window.frames["iframe"].document.oncontextmenu = function(){alert("No way!"); return false;};   
//}
}
/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class DialogModel {

  
  constructor(public image: string,public otherdoc: string,public audio:string,public video:string,public codecs:string) {
  }
}
