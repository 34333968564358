import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./auth.guard";
import { LoginComponent } from "./main/pages/authentication/login/login.component";
import { RefreshComponent } from "./refresh.component";
import { ProfileComponent } from './profile/profile.component';
const routes: Routes = [
    {path: 'profile', component: ProfileComponent},
    {
        path: 'apps',
        loadChildren: './main/apps/apps.module#AppsModule'
    },
    {
        path: 'pages',
        loadChildren: './main/pages/pages.module#PagesModule'
    },
    {
        path: 'filemanager',
        canActivate: [AuthGuard],
        loadChildren: './file-manager/file-manager.module#FileManagerModule'
    },
    {
        path: 'salesorder',
        canActivate: [AuthGuard],
        loadChildren: './sales-order/sales-order.module#SalesOrderModule'
    },
    {
        path: 'fileexplorer',
        canActivate: [AuthGuard],
        loadChildren: './file-explorer/file-explorer.module#FileExplorerModule'
    },
    {
        path: 'doclist',
        // canActivate: [AuthGuard],
        loadChildren: './doclist/doclist.module#DoclistModule'
    },
    {
        path: "",
        component: LoginComponent
    },
    {
        path: "app/refresh",
        canActivate: [AuthGuard],
        component: RefreshComponent
    },
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
export const routing = RouterModule.forRoot(routes, { useHash: true });